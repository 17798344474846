import './src/styles/global.css';
import './src/styles/language-tabs.css';
import './src/styles/hbspt.css';

import { wrapRootElement as wrap } from './wrap-root-element';

// Configurações existentes
export const wrapRootElement = wrap;

// Manipulador de eventos onRouteUpdate para ajustar a visibilidade do corpo do documento
export const onRouteUpdate = () => {
  document.body.style.visibility = 'visible';
};
